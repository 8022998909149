import React, { useState } from "react";
import Cloud from "@ypi/client-sdk";
import { Container } from "@nodeme/grid-react";
import Form from "@nodeme/forms-react/Form";
import Text from "@nodeme/forms-react/Text";
import Artwork from "./Artwork";
import Button from "@nodeme/forms-react/Button";
import Browser from "./Browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintBrush } from "@fortawesome/pro-light-svg-icons/faPaintBrush";
import { faTrash } from "@fortawesome/pro-light-svg-icons/faTrash";
import { buildClasses } from "@nodeme/jss-react";
import { Grid } from "@nodeme/grid-react";

export default class Registrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9"
      },
      projection: [
        "id",
        "firstname",
        "lastname",
        "email",
        "phone",
        "education",
        "website",
        "birthdate",
        "createdAt"
      ],
      sorting: { f: "createdAt", d: "desc" },
      limit: 5,
      registrations: []
    };
  }

  async componentDidMount() {}

  render() {
    const { query, projection, sorting, limit } = this.state;
    return (
      <Container notFluid>
        <Browser
          model="Registration"
          query={query}
          projection={projection}
          sorting={sorting}
          limit={limit}
          render={(reg, index, elements, setElements) => {
            return (
              <Registration
                key={reg.id}
                {...reg}
                index={index}
                onDelete={() =>
                  setElements(elements.filter(ele => ele.id !== reg.id))
                }
              />
            );
          }}
        />
      </Container>
    );
  }
}

const classStyles = theme => definitions => ({
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: 1
  },
  wrapper: {
    display: "table",
    width: "100%",
    height: "100%"
  },
  aligner: {
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    width: "100%"
  }
});

async function deleteRegistration(registration, state, setState, onDelete) {
  try {
    setState({
      ...state,
      pendingState: "Bilder werden gelöscht"
    });
    const result = await Cloud.models.Artwork.get(
      {
        pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" },
        registration
      },
      ["id", "title", "thumb"],
      false,
      100
    );
    const thumbs = result.data || [];

    const deleteThumbs = thumbs.map(async ele => {
      return Cloud.models.Artwork.file.delete(ele.thumb, "thumb", {
        id: ele.id,
        pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
      });
    });

    await Promise.all(deleteThumbs);

    setState({
      ...state,
      pendingState: "Werke werden gelöscht"
    });
    const deleteWork = thumbs.map(async ele => {
      return Cloud.models.Artwork.delete({
        id: ele.id,
        pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
      });
    });

    await Promise.all(deleteWork);

    setState({
      ...state,
      pendingState: "Registrierung wird gelöscht"
    });
    await Cloud.models.Registration.delete({
      event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9",
      id: registration
    });

    onDelete();
  } catch (err) {
    setState({
      ...state,
      error: true
    });
  }
}

async function loadArtwork(registration, state, setState) {
  try {
    setState({ ...state, pending: true, loaded: false });
    const result = await Cloud.models.Artwork.get(
      {
        pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" },
        registration
      },
      [
        "id",
        "thumb",
        "mockup",
        "title",
        "category",
        "technique",
        "year",
        "description",
        "width",
        "height",
        "depth",
        "signed",
        "dated",
        "frame",
        "selling",
        "place",
        "eventState"
      ],
      false,
      100
    );
    setState({ ...state, pending: false, artworks: result.data, loaded: true });
  } catch (err) {}
}

function Registration(props) {
  const [deleteState, setDeleteState] = useState({
    confirm: false,
    pendingState: null,
    error: false,
    deleted: false
  });

  const [artworkState, setArtworkState] = useState({
    pending: false,
    error: false,
    artworks: [],
    loaded: false
  });

  const classes = buildClasses(classStyles);
  const accepted = artworkState.artworks.find(
    artwork => artwork.eventState === 1
  );

  return (
    <div
      key={props.id}
      style={{
        backgroundColor: "#fff",
        borderTop: `1px solid ${props.index !== 0 ? "#e4e4e4" : "#fff"}`,
        marginBottom: "15px",
        position: "relative"
      }}
    >
      {!deleteState.deleted && deleteState.confirm && (
        <div className={classes.overlay}>
          <div className={classes.wrapper}>
            <span className={classes.aligner}>
              {!deleteState.error && !Boolean(deleteState.pendingState) && (
                <div>
                  Wollen Sie{" "}
                  <b>
                    {props.lastname} {props.firstname}
                  </b>{" "}
                  wirklich löschen?
                  <br />
                  <Form>
                    <Grid spacing={8} vertical justify="center">
                      <Button
                        xs={"auto"}
                        danger
                        onClick={e => {
                          e.preventDefault();
                          deleteRegistration(
                            props.id,
                            deleteState,
                            setDeleteState,
                            props.onDelete
                          );
                        }}
                      >
                        Löschen
                      </Button>
                      <Button
                        xs="auto"
                        default
                        onClick={e => {
                          e.preventDefault();
                          setDeleteState({ ...deleteState, confirm: false });
                        }}
                      >
                        Abbrechen
                      </Button>
                    </Grid>
                  </Form>
                </div>
              )}
              {!deleteState.error && Boolean(deleteState.pendingState) && (
                <div>{deleteState.pendingState}...</div>
              )}
              {deleteState.error && (
                <div>
                  <b>FEHLER</b> bei:
                  <br />
                  {deleteState.pendingState}
                </div>
              )}
            </span>
          </div>
        </div>
      )}
      {deleteState.deleted && (
        <div
          className={classes.overlay}
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        />
      )}
      <Form spacing={16} vertical>
        <h1>
          {props.lastname} <small>{props.firstname}</small>
          <div
            style={{
              fontSize: "0.8rem",
              fontWeight: 400,
              color: "rgb(130, 130, 130)",
              float: "right"
            }}
          >
            {new Date(props.createdAt).toLocaleDateString()}
          </div>
        </h1>
        <Text
          xs={10}
          sm={4}
          lg={2}
          readOnly
          label="Geburtsdatum"
          value={props.birthdate}
        />
        <Text
          xs={10}
          sm={4}
          lg={2}
          readOnly
          label="Email"
          value={props.email}
        />
        <Text xs={10} sm={4} lg={2} readOnly label="Tel." value={props.phone} />
        <Text
          xs={10}
          sm={4}
          lg={2}
          readOnly
          label="Website"
          value={props.website}
        />
        {!deleteState.deleted && (
          <div xs={10} lg={2} style={{ textAlign: "right" }}>
            <Button
              danger
              width="32px"
              onClick={e => {
                e.preventDefault();
                setDeleteState({ ...deleteState, confirm: true });
              }}
              style={{ marginRight: "8px" }}
            >
              <FontAwesomeIcon style={{ fontSize: "1.1rem" }} icon={faTrash} />
            </Button>
            {(!artworkState.pending ||
              (!artworkState.pending && !artworkState.artworks.length)) && (
              <Button
                primary
                width="32px"
                onClick={e => {
                  e.preventDefault();
                  loadArtwork(props.id, artworkState, setArtworkState);
                }}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "1.1rem" }}
                  icon={faPaintBrush}
                />
              </Button>
            )}
          </div>
        )}
        <div />
      </Form>
      {false && Boolean(accepted) && (
        <Artwork
          {...accepted}
          setArtwork={artwork => {
            const index = artworkState.artworks.findIndex(
              ele => ele.id === artwork.id
            );
            setArtworkState({
              ...artworkState,
              artworks: artworkState.artworks.map((item, itemIndex) => {
                if (index !== itemIndex) return item;
                return artwork;
              })
            });
          }}
        />
      )}
      {(true || !Boolean(accepted)) &&
        artworkState.artworks.map((art, index) => {
          return (
            <Artwork
              key={art.id}
              {...art}
              setArtwork={artwork => {
                setArtworkState({
                  ...artworkState,
                  artworks: artworkState.artworks.map((item, itemIndex) => {
                    if (index !== itemIndex) return item;
                    return artwork;
                  })
                });
              }}
            />
          );
        })}
      {artworkState.pending && (
        <div style={{ textAlign: "center" }}>
          <i>Lade Werke...</i>
        </div>
      )}
      {artworkState.loaded && !artworkState.artworks.length && (
        <div style={{ textAlign: "center" }}>
          <i>Es wuren noch keine Werke hinzugefügt.</i>
        </div>
      )}
    </div>
  );
}
