import React from "react";
import Artwork from "./index";
import Browser from "./Browser";
import { Container, Grid } from "@nodeme/grid-react";

export default class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        eventState: 1,
        pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
      },
      projection: [
        "id",
        "lot",
        "artist",
        "title",
        "category",
        "technique",
        "year",
        "selling",
        "width",
        "height",
        "depth",
        "thumb",
        "mockup",
        "signed",
        "dated",
        "frame",
        "registration",
        "description",
        "createdAt"
      ],
      sorting: { f: "lot", d: "asc" },
      limit: 12
    };
  }

  render() {
    const { query, projection, sorting, limit } = this.state;
    return (
      <Container notFluid>
        <Grid>
          <Browser
            model="Artwork"
            query={query}
            projection={projection}
            sorting={sorting}
            limit={limit}
            root={{ left: 0, right: 0 }}
            column={4}
            pending={
              <div
                xs={5}
                sm={5}
                md={10 / 3}
                lg={10 / 4}
                style={{ position: "relative", height: "250px", color: "#fff" }}
              >
                Werke werden geladen...
              </div>
            }
            render={(art, index, elements, setElements) => {
              return (
                <Artwork
                  key={art.id}
                  onClick={e => {
                    setElements(
                      elements.map(item => {
                        if (item.id === art.id) {
                          return { ...art, active: !art.active };
                        }
                        return item;
                      })
                    );
                  }}
                  xs={Boolean(art.active) ? 10 : 5}
                  sm={Boolean(art.active) ? 10 : 5}
                  md={Boolean(art.active) ? 10 : 10 / 3}
                  lg={Boolean(art.active) ? 10 : 10 / 4}
                  {...art}
                />
              );
            }}
          />
        </Grid>
      </Container>
    );
  }
}
