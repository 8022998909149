import React from "react";
import Cloud from "@ypi/client-sdk";
import { Grid } from "@nodeme/grid-react";
import Form from "@nodeme/forms-react/Form";
import Text from "@nodeme/forms-react/Text";
import Checkbox from "@nodeme/forms-react/Checkbox";
import UploadArea from "@nodeme/forms-react/UploadArea";
import Button from "@nodeme/forms-react/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons/faCheckCircle";

export default class Registrations extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      img: null,
      pendingImg: false,
      errorImg: false,
      pending: true,
      error: false,
      thumb: props.thumb,
      mockup: props.mockup,
      mockupImg: null,
      mockupPending: Boolean(props.mockup),
      mockupError: null
    };

    this.accept = this.accept.bind(this);
    this.decline = this.decline.bind(this);
    this.setMockup = this.setMockup.bind(this);
    this.loadMockup = this.loadMockup.bind(this);
    this.deleteMockup = this.deleteMockup.bind(this);
    this.loadThumb = this.loadThumb.bind(this);
    this.setThumb = this.setThumb.bind(this);
    this.deleteThumb = this.deleteThumb.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.loadThumb();
    this.loadMockup();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async accept() {
    try {
      await Cloud.models.Artwork.update(
        {
          eventState: 1
        },
        {
          id: this.props.id,
          pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
        }
      );

      this.props.setArtwork({ ...this.props, eventState: 1 });
    } catch (err) {
      console.log("ERROR", err);
    }
  }

  async decline() {
    try {
      await Cloud.models.Artwork.update(
        {
          eventState: 0
        },
        {
          id: this.props.id,
          pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
        }
      );

      this.props.setArtwork({ ...this.props, eventState: 0 });
    } catch (err) {
      console.log("ERROR", err);
    }
  }

  setMockup(mockup) {
    if (mockup) {
      const reader = new FileReader();
      this.setState({ ...this.state, mockupPending: true });
      reader.onloadend = async () => {
        try {
          const result = await Cloud.models.Artwork.file.upload(
            mockup.name,
            "mockup",
            reader.result,
            {
              id: this.props.id,
              pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
            }
          );

          this.setState({
            ...this.state,
            mockup: result.data.name,
            mockupPending: false,
            mockupError: null
          });
        } catch (error) {
          this.setState({
            ...this.state,
            mockupPending: false,
            mockupError: "Bild konnte nicht hochgeladen werden."
          });
        }
        this.loadMockup();
      };

      reader.readAsArrayBuffer(mockup);
    }
  }

  async loadMockup() {
    if (!this.state.mockup) return;
    try {
      const img = await Cloud.models.Artwork.file.getImage(
        this.state.mockup,
        "mockup",
        "xs",
        {
          id: this.props.id,
          pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
        }
      );

      if (this._isMounted)
        this.setState({
          ...this.state,
          mockupImg: URL.createObjectURL(img),
          mockupPending: false
        });
    } catch (err) {
      if (this._isMounted)
        this.setState({ ...this.state, pending: false, error: true });
    }
  }

  async deleteMockup() {
    if (!this.state.mockup) return;
    try {
      await Cloud.models.Artwork.file.delete(this.state.mockup, "mockup", {
        id: this.props.id,
        pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
      });

      if (this._isMounted)
        this.setState({
          ...this.state,
          mockup: null,
          mockupImg: null,
          mockupPending: false
        });
    } catch (err) {
      if (this._isMounted)
        this.setState({ ...this.state, pending: false, error: true });
    }
  }
  async loadThumb() {
    if (this.state.thumb) {
      try {
        const img = await Cloud.models.Artwork.file.getImage(
          this.state.thumb,
          "thumb",
          "xs",
          {
            id: this.props.id,
            pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
          }
        );
        if (this._isMounted)
          this.setState({
            ...this.state,
            img: URL.createObjectURL(img),
            pending: false
          });
      } catch (err) {
        if (this._isMounted)
          this.setState({ ...this.state, pending: false, error: true });
      }
    }
  }
  setThumb(thumb) {
    if (thumb) {
      const reader = new FileReader();
      this.setState({ ...this.state, pendingImg: true });
      reader.onloadend = async () => {
        try {
          const result = await Cloud.models.Artwork.file.upload(
            thumb.name,
            "thumb",
            reader.result,
            {
              id: this.props.id,
              pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
            }
          );

          this.setState({
            ...this.state,
            thumb: result.data.name,
            pendingImg: false,
            errorImg: false
          });
        } catch (error) {
          this.setState({
            ...this.state,
            pendingImg: false,
            errorImg: "Bild konnte nicht hochgeladen werden."
          });
        }
        this.loadThumb();
      };

      reader.readAsArrayBuffer(thumb);
    }
  }

  async deleteThumb() {
    if (!this.state.img) return;
    this.setState({
      ...this.state,
      pendingImg: true,
      errorImg: false,
      img: null
    });
    try {
      await Cloud.models.Artwork.file.delete(this.state.thumb, "thumb", {
        id: this.props.id,
        pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
      });

      if (this._isMounted)
        this.setState({
          ...this.state,
          thumb: null,
          pendingImg: false,
          errorImg: false,
          img: null
        });
    } catch (err) {
      if (this._isMounted)
        this.setState({ ...this.state, pendingImg: false, errorImg: true });
    }
  }

  render() {
    const {
      title,
      category,
      technique,
      year,
      description,
      width,
      height,
      depth,
      signed,
      dated,
      frame,
      place,
      eventState
    } = this.props;
    const price = this.props.selling.value;
    const {
      pending,
      error,
      img,
      mockupImg,
      mockupError,
      mockupPending
    } = this.state;

    const mockup = this.state.mockup;
    return (
      <div
        style={{
          border: "1px solid rgba(215, 215, 215, 1)",
          borderRadius: "4px"
        }}
      >
        <Grid spacing={16} vertical>
          <Grid item sm={5} lg={3}>
            {!this.state.thumb && !this.state.pendingImg && (
              <UploadArea
                height="250px"
                value={this.state.thumb}
                onChange={this.setThumb}
                error={this.state.errorImg}
              >
                <div
                  style={{
                    display: "table",
                    height: "100%",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      display: "table-cell",
                      verticalAlign: "middle",
                      textAlign: "center"
                    }}
                  >
                    Thumb hochladen
                  </div>
                </div>
              </UploadArea>
            )}
            {error && (
              <div style={{ color: "fff" }}>
                Bild konnte nicht geladen werden.
              </div>
            )}
            {Boolean(this.state.thumb) && pending && <div>Lade Bild...</div>}
            {this.state.pendingImg && <div>Thumb wird hochgeladen...</div>}
            {img && !this.state.pendingImg && (
              <img
                style={{ maxWidth: "100%" }}
                alt={this.state.thumb}
                src={img}
              />
            )}
            {img && !pending && (
              <Button
                danger
                onClick={e => {
                  e.preventDefault();
                  this.deleteThumb();
                }}
              >
                Thumb Löschen
              </Button>
            )}
            {Boolean(eventState) && (
              <div>
                {mockupImg && (
                  <img
                    src={mockupImg}
                    alt="Mockup"
                    style={{ maxWidth: "100%", marginTop: "25px" }}
                  />
                )}
                {mockup && (
                  <Button
                    danger
                    onClick={e => {
                      e.preventDefault();
                      this.deleteMockup();
                    }}
                  >
                    Mockup Löschen
                  </Button>
                )}
                {mockupPending && (
                  <div
                    style={{
                      display: "table",
                      height: "250px",
                      width: "100%"
                    }}
                  >
                    <div
                      style={{
                        display: "table-cell",
                        verticalAlign: "middle",
                        textAlign: "center"
                      }}
                    >
                      <i>Mockup wird hochgeladen...</i>
                    </div>
                  </div>
                )}
                {!mockup && !mockupPending && (
                  <UploadArea
                    height="250px"
                    value={this.state.mockup}
                    onChange={this.setMockup}
                    error={mockupError}
                  >
                    <div
                      style={{
                        display: "table",
                        height: "100%",
                        width: "100%"
                      }}
                    >
                      <div
                        style={{
                          display: "table-cell",
                          verticalAlign: "middle",
                          textAlign: "center"
                        }}
                      >
                        Mockup hochladen
                      </div>
                    </div>
                  </UploadArea>
                )}
              </div>
            )}
          </Grid>
          <Grid item sm={5} lg={7}>
            <h3>
              {title}{" "}
              {Boolean(eventState) && (
                <FontAwesomeIcon
                  style={{ color: "rgb(85, 149, 84)" }}
                  icon={faCheckCircle}
                />
              )}
            </h3>
            <Form spacing={16}>
              <Text
                readOnly
                label="Kategorie"
                value={category}
                xs={10}
                md={5}
                lg={10 / 4}
              />
              <Text
                readOnly
                label="Technik"
                value={technique}
                xs={10}
                md={5}
                lg={10 / 4}
              />
              <Text
                readOnly
                label="Jahr"
                value={year}
                xs={10}
                md={5}
                lg={10 / 4}
              />
              <Text
                readOnly
                label="Größe"
                value={width + "x" + height + "x" + depth + "cm"}
                xs={10}
                md={5}
                lg={10 / 4}
              />
              <Text
                readOnly
                label="Standort"
                value={place}
                xs={10}
                md={5}
                lg={10 / 4}
              />
              <Text
                readOnly
                label="Preis"
                value={price + "€"}
                xs={10}
                md={5}
                lg={10 / 4}
              />
              <Grid spacing={16} vertical>
                <Checkbox
                  xs={5}
                  sm={10 / 3}
                  label="Signiert"
                  value={signed}
                  onChange={() => {}}
                />
                <Checkbox
                  xs={5}
                  sm={10 / 3}
                  label="Datiert"
                  value={dated}
                  onChange={() => {}}
                />
                <Checkbox
                  xs={5}
                  sm={10 / 3}
                  label="Gerahmt"
                  value={frame}
                  onChange={() => {}}
                />
              </Grid>
              <div>
                <i>{description}</i>
              </div>
              <div style={{ textAlign: "right" }}>
                {false && !eventState && (
                  <Button
                    width="150px"
                    success
                    onClick={e => {
                      e.preventDefault();
                      this.accept();
                    }}
                  >
                    Annhemen
                  </Button>
                )}
                {false && Boolean(eventState) && (
                  <Button
                    width="150px"
                    default
                    onClick={e => {
                      e.preventDefault();
                      this.decline();
                    }}
                  >
                    Zurücksetzen
                  </Button>
                )}
              </div>
            </Form>
          </Grid>
        </Grid>
      </div>
    );
  }
}
