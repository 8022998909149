import React, { useState, useEffect } from "react";
import { buildClasses } from "@nodeme/jss-react";
import Cloud from "@ypi/client-sdk";
import { Grid } from "@nodeme/grid-react";
import { Form } from "@nodeme/forms-react";
import Checkbox from "@nodeme/forms-react/Checkbox";
import Text from "@nodeme/forms-react/Text";
import Number from "@nodeme/forms-react/Number";
import Button from "@nodeme/forms-react/Button";

const styles = theme => definition => ({
  holder: {
    backgroundColor: "#fff"
  },
  thumb: {
    maxWidth: "70%"
  },
  mockup: {
    maxWidth: "calc(30% - 16px)",
    marginRight: "16px"
  },
  headline: {
    margin: "0px",
    fontWeight: 400
  },
  text: {
    color: "#000",
    textAlign: "left"
  },
  label: {
    fontSize: "0.8rem",
    fontWeight: 100,
    marginTop: "16px",
    display: "block",
    color: "rgba(255, 255, 255, 0.8)"
  },
  smallText: {
    color: "#000",
    textAlign: "left"
  }
});

async function saveArt(
  {
    artist,
    lot,
    title,
    category,
    technique,
    year,
    width,
    height,
    depth,
    selling,
    signed,
    dated,
    frame,
    description
  },
  id,
  state,
  setState
) {
  setState({ ...state, pending: true });
  try {
    await Cloud.models.Artwork.update(
      {
        lot,
        title,
        category,
        technique,
        year,
        width,
        height,
        depth,
        selling,
        signed,
        dated,
        frame,
        artist: artist.name,
        description
      },
      { id, pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" } }
    );
  } catch (err) {
    alert("ERROR");
  }
  setState({ ...state, pending: false });
}
async function saveArtist({ education }, id, artist, setArtist) {
  setArtist({ ...artist, pendingUpdate: true });
  try {
    await Cloud.models.Registration.update(
      {
        education
      },
      { id, event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9" }
    );
  } catch (err) {
    alert("ERROR");
  }
  setArtist({ ...artist, pendingUpdate: false });
}

function loadImagesAndArtist(props, state, setState) {
  return () => {
    (async () => {
      try {
        const img = await Cloud.models.Artwork.file.getImage(
          props.thumbName,
          "thumb",
          "md",
          {
            id: props.id,
            pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
          }
        );
        props.setThumb({
          ...props.thumb,
          pending: false,
          src: URL.createObjectURL(img)
        });
      } catch (err) {
        props.setThumb({ ...props.thumb, pending: false, error: true });
      }
    })();
    (async () => {
      try {
        const img = await Cloud.models.Artwork.file.getImage(
          props.mockupName,
          "mockup",
          "md",
          {
            id: props.id,
            pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
          }
        );
        props.setMockup({
          ...props.mockup,
          pending: false,
          src: URL.createObjectURL(img)
        });
      } catch (err) {
        props.setMockup({ ...props.mockup, pending: false, error: true });
      }
    })();
    (async () => {
      try {
        const img = await Cloud.models.Artwork.file.getImage(
          props.mockupName,
          "mockup",
          "md",
          {
            id: props.id,
            pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
          }
        );
        props.setMockup({
          ...props.mockup,
          pending: false,
          src: URL.createObjectURL(img)
        });
      } catch (err) {
        props.setMockup({ ...props.mockup, pending: false, error: true });
      }
    })();
    (async () => {
      try {
        const registration = await Cloud.models.Registration.get(
          {
            id: props.registration,
            event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9"
          },
          ["education", "birthdate"],
          false,
          1
        );
        setState({
          ...state,
          values: {
            education: registration.data.education,
            birthdate: registration.data.birthdate
          },
          pending: false
        });
      } catch (err) {}
    })();
  };
}

const Details = props => {
  const [state, setstate] = useState({
    values: {
      ...props,
      selling: { ...props.selling, available: Boolean(props.selling.available) }
    },
    errors: {},
    pending: false
  });

  const [artist, setArtist] = useState({
    values: {},
    errors: {},
    pending: true,
    pendingUpdate: false
  });

  const {
    title,
    lot,
    category,
    technique,
    year,
    width,
    height,
    depth,
    selling,
    signed,
    dated,
    frame,
    description
  } = state.values;
  const artistName = state.values.artist.name;
  const { thumb, mockup } = props;

  const classes = buildClasses(styles, props);

  useEffect(loadImagesAndArtist(props, artist, setArtist), []);

  if (thumb.pending || mockup.pending || artist.pending)
    return (
      <div style={{ position: "relative", height: "300px" }}>Lade Werke...</div>
    );

  return (
    <div className={classes.holder}>
      <Grid spacing={16} vertical>
        <div xs={10} sm={10} lg={6}>
          <div
            className={classes.mockup}
            style={{ position: "relative", display: "inline-block" }}
          >
            <img
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              alt="mockup"
              src={mockup.src}
            />
          </div>

          <div
            className={classes.thumb}
            style={{ position: "relative", display: "inline-block" }}
          >
            <img
              alt="thumb"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              src={thumb.src}
            />
          </div>
        </div>
        <div xs={10} sm={10} lg={4} className={classes.text}>
          <Form spacing={8}>
            <Text
              xs={8}
              label="Titel"
              onChange={name => {
                setstate({
                  ...state,
                  values: {
                    ...state.values,
                    artist: { ...state.values.artist, name }
                  }
                });
              }}
              value={artistName}
            />
            <Text
              xs={8}
              label="Titel"
              onChange={value => {
                setstate({
                  ...state,
                  values: { ...state.values, title: value }
                });
              }}
              value={title}
            />
            <Number
              xs={2}
              label="Lot"
              onChange={value => {
                setstate({
                  ...state,
                  values: { ...state.values, lot: parseInt(value) }
                });
              }}
              value={lot}
            />
            <Text
              xs={5}
              sm={5}
              lg={10 / 3}
              label="Kategorie"
              onChange={value => {
                setstate({
                  ...state,
                  values: { ...state.values, category: value }
                });
              }}
              value={category}
            />
            <Text
              xs={5}
              sm={5}
              lg={10 / 3}
              label="Technik"
              value={technique}
              onChange={value => {
                setstate({
                  ...state,
                  values: { ...state.values, technique: value }
                });
              }}
            />
            <Number
              xs={5}
              sm={5}
              lg={10 / 3}
              label="Jahr"
              value={year}
              onChange={value => {
                setstate({
                  ...state,
                  values: { ...state.values, year: value }
                });
              }}
            />
            <Text
              xs={5}
              sm={5}
              lg={10 / 3}
              label="Breite"
              value={width}
              onChange={value => {
                setstate({
                  ...state,
                  values: { ...state.values, width: parseFloat(value) }
                });
              }}
            />
            <Text
              xs={5}
              sm={5}
              lg={10 / 3}
              label="Höhe"
              value={height}
              onChange={value => {
                setstate({
                  ...state,
                  values: { ...state.values, height: parseFloat(value) }
                });
              }}
            />
            <Text
              xs={5}
              sm={5}
              lg={10 / 3}
              label="Tiefe"
              value={depth}
              onChange={value => {
                setstate({
                  ...state,
                  values: { ...state.values, depth: parseFloat(value) }
                });
              }}
            />
            <Number
              xs={5}
              sm={5}
              label="Listenpreis"
              value={(selling && selling.value) || ""}
              onChange={value => {
                setstate({
                  ...state,
                  values: {
                    ...state.values,
                    selling: {
                      ...state.values.selling,
                      value: parseFloat(value)
                    }
                  }
                });
              }}
            />
            <Number
              xs={5}
              sm={5}
              label="Rufpreis"
              value={(selling && selling.starting) || ""}
              onChange={starting => {
                setstate({
                  ...state,
                  values: {
                    ...state.values,
                    selling: {
                      ...state.values.selling,
                      starting: parseFloat(starting)
                    }
                  }
                });
              }}
            />
            <Number
              xs={5}
              sm={5}
              label="Verkaufspreis"
              value={(selling && selling.achieved) || ""}
              onChange={achieved => {
                setstate({
                  ...state,
                  values: {
                    ...state.values,
                    selling: {
                      ...state.values.selling,
                      achieved: parseFloat(achieved)
                    }
                  }
                });
              }}
            />
            <Number
              xs={5}
              sm={5}
              label="Nachverkaufspreis"
              value={(selling && selling.reduced) || ""}
              onChange={reduced => {
                setstate({
                  ...state,
                  values: {
                    ...state.values,
                    selling: {
                      ...state.values.selling,
                      reduced: parseFloat(reduced)
                    }
                  }
                });
              }}
            />
            <Grid spacing={16} root={{ left: 8, right: 8 }} vertical>
              <Checkbox
                xs={5}
                sm={5}
                lg={10 / 3}
                label="Verfügbar"
                onChange={available => {
                  setstate({
                    ...state,
                    values: {
                      ...state.values,
                      selling: {
                        ...state.values.selling,
                        available: Boolean(available)
                      }
                    }
                  });
                }}
                value={Boolean(selling && selling.available)}
              />
            </Grid>
            <Grid spacing={16} root={{ left: 8, right: 8 }} vertical>
              <Checkbox
                xs={5}
                sm={5}
                lg={10 / 3}
                label="Signiert"
                onChange={signed => {
                  setstate({
                    ...state,
                    values: { ...state.values, signed }
                  });
                }}
                value={signed}
              />
              <Checkbox
                xs={5}
                sm={5}
                lg={10 / 3}
                label="Datiert"
                onChange={dated => {
                  setstate({
                    ...state,
                    values: { ...state.values, dated }
                  });
                }}
                value={dated}
              />
              <Checkbox
                xs={5}
                sm={5}
                lg={10 / 3}
                label="Gerahmt"
                onChange={frame => {
                  setstate({
                    ...state,
                    values: { ...state.values, frame }
                  });
                }}
                value={Boolean(frame)}
              />
            </Grid>
            <Text
              xs={12}
              rows={6}
              label="Beschreibung"
              onChange={description => {
                setstate({
                  ...state,
                  values: { ...state.values, description }
                });
              }}
              value={description || ""}
            />
            {state.pending && <span>Speicher Werk...</span>}
            {!state.pending && (
              <Button
                xs="240px"
                primary
                onClick={e => {
                  e.preventDefault();
                  saveArt(state.values, props.id, state, setstate);
                }}
              >
                Werk speichern
              </Button>
            )}
            <div></div>
          </Form>
        </div>
        <Text
          xs={12}
          rows={6}
          label="Beschreibung"
          value={artist.values.education || ""}
          onChange={education => {
            setArtist({
              ...artist,
              values: { ...artist.values, education }
            });
          }}
        />
        {artist.pendingUpdate && <span>Speicher Künstler...</span>}
        {!artist.pendingUpdate && (
          <Button
            xs="240px"
            primary
            onClick={e => {
              e.preventDefault();
              saveArtist(artist.values, props.registration, artist, setArtist);
            }}
          >
            Künstler speichern
          </Button>
        )}
      </Grid>
    </div>
  );
};

export default Details;
