import React from "react";
import Preview from "./Preview";
import Details from "./Details";
import Cloud from "@ypi/client-sdk";

export default class Artwork extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      thumb: {
        pending: true,
        src: null,
        error: false
      },
      thumbXl: {
        pending: true,
        src: null,
        error: false
      },
      mockup: {
        pending: true,
        src: null,
        error: false
      },
      artist: {
        education: null,
        birthDate: null,
        pending: false,
        error: false
      },
      active: false
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    try {
      const img = await Cloud.models.Artwork.file.getImage(
        this.props.thumb,
        "thumb",
        "xs",
        {
          id: this.props.id,
          pool: { $in: "5d5d42a6-f414-4a7d-9aea-dfd93443718f" }
        }
      );
      if (this._isMounted)
        this.setState({
          ...this.state,
          thumb: {
            src: URL.createObjectURL(img),
            pending: false
          }
        });
    } catch (err) {
      if (this._isMounted)
        this.setState({ ...this.state, pending: false, error: true });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { artist, lot, active } = this.props;
    const { src } = this.state.thumb;
    const { _isMounted } = this;
    if (!active)
      return (
        <div
          onClick={() => {
            this.props.onClick();
          }}
        >
          <Preview artist={artist} lot={lot} thumb={src} />
        </div>
      );
    return (
      <Details
        {...this.props}
        thumbName={this.props.thumb}
        thumb={this.state.thumbXl}
        setThumb={thumbXl => {
          if (_isMounted) this.setState({ ...this.state, thumbXl });
        }}
        mockupName={this.props.mockup}
        mockup={this.state.mockup}
        setMockup={mockup => {
          if (_isMounted) this.setState({ ...this.state, mockup });
        }}
        artist={{ ...this.state.artist, name: this.props.artist }}
        setArtist={artist => {
          if (_isMounted) this.setState({ ...this.state, artist });
        }}
      />
    );
  }
}
