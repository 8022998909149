import React from "react";
import { buildClasses } from "@nodeme/jss-react";
const styles = theme => definition => ({
  holder: {
    textAlign: "left"
  },
  thumbPlaceholer: {
    paddingTop: "100%",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    backgroundRepeat: "no-repeat"
  },
  thumb: {
    paddingTop: "100%",
    width: "100%",
    borderRadius: "0px",
    backgroundColor: "rgba(255, 255, 255, 0)",
    backgroundRepeat: "no-repeat"
  },
  artist: {
    color: "#fff",
    fontWeight: "400",
    fontSize: "1rem",
    marginBottom: "2px"
  },
  lot: {
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "100",
    fontSize: "0.8rem",
    marginTop: "2px"
  }
});
const Preview = props => {
  const { artist, lot, thumb } = props;
  const classes = buildClasses(styles, props);
  return (
    <div className={classes.holder}>
      {!thumb && <div className={classes.thumbPlaceholer}></div>}
      {Boolean(thumb) && (
        <div
          className={classes.thumb}
          style={{
            backgroundImage: `url(${thumb})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
          }}
        ></div>
      )}
      <h2 className={classes.artist}>{artist}</h2>
      <p className={classes.lot}>Lot {lot}</p>
    </div>
  );
};

export default Preview;
