import React from "react";
import Registrations from "./components/Registrations";
import Biddings from "./components/Bidding";
import Info from "./components/Info";
import { Container } from "@nodeme/grid-react";
import Form from "@nodeme/forms-react/Form";
import Button from "@nodeme/forms-react/Button";
import Cloud from "@ypi/client-sdk";
import List from "./components/Artworks/List";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      error: false,
      show: 3
    };
  }

  componentDidMount() {
    (async () => {
      try {
        await Cloud.init({
          idp:
            "https://europe-west1-authentication-cfd43.cloudfunctions.net/app",
          appId: "3b4ce81d-1760-4a68-946d-5fdf408a7b79",
          apiKey: "IDMFeLn6mcyjsNXslX3zuwPH5bNokJD7",
          domain: "localhost:3000",
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ijk0M2E5YmE5LWE4NmQtNDk4Ni1iZDRhLTc0NDZjMDNiODE4NiIsImlzcyI6Imh0dHBzOi8vZXVyb3BlLXdlc3QxLWF1dGhlbnRpY2F0aW9uLWNmZDQzLmNsb3VkZnVuY3Rpb25zLm5ldC9hcHAiLCJhdWQiOiJodHRwczovL2V1cm9wZS13ZXN0MS1hdXRoZW50aWNhdGlvbi1jZmQ0My5jbG91ZGZ1bmN0aW9ucy5uZXQvYXBwIiwic3ViIjoiMDE0M2U0NmMtOThkOS00YmVlLTkwMDAtYWMxYTAyNTRmYmM4Iiwic2NvcGVzIjpbIkF1dGhlbnRpY2F0aW9uOnVzZXI6cHVibGljOmFwcDpyZWFkIl0sImlhdCI6MTU2MzI3NDMwNn0.nfCuUjauXvo9IQNZ4hGOBxvfks84Ew1wFdXfyzQ-rBw",
          models: [
            {
              name: "Artwork",
              serverName: "Art",
              server:
                "https://europe-west1-artwork-e7eca.cloudfunctions.net/app",
              type: "resource"
            },
            {
              name: "Registration",
              serverName: "Art",
              server:
                "https://europe-west1-artwork-e7eca.cloudfunctions.net/app",
              type: "resource"
            },
            {
              name: "Bidding",
              serverName: "Art",
              server:
                "https://europe-west1-artwork-e7eca.cloudfunctions.net/app",
              type: "resource"
            },
            {
              name: "Info",
              serverName: "Art",
              server:
                "https://europe-west1-artwork-e7eca.cloudfunctions.net/app",
              type: "resource"
            }
          ]
        });

        this.setState({ pending: false, error: false });
      } catch (err) {
        this.setState({ pending: false, error: true });
      }
    })();
  }

  render() {
    if (this.state.pending) return <div>Lade...</div>;
    if (this.state.error) return <div>Ein Fehler...</div>;
    return (
      <div className="App">
        <Container notFluid>
          <Form spacing={16}>
            <Button
              xs="140px"
              primary={this.state.show === 0}
              onClick={e => {
                e.preventDefault();
                if (this.state.show !== 0)
                  this.setState({ ...this.state, show: 0 });
              }}
            >
              Werke
            </Button>
            <Button
              xs="140px"
              primary={this.state.show === 1}
              onClick={e => {
                e.preventDefault();
                if (this.state.show !== 1)
                  this.setState({ ...this.state, show: 1 });
              }}
            >
              Registrierungen
            </Button>
            <Button
              xs="140px"
              primary={this.state.show === 2}
              onClick={e => {
                e.preventDefault();
                if (this.state.show !== 2)
                  this.setState({ ...this.state, show: 2 });
              }}
            >
              Gebote
            </Button>
            <Button
              xs="140px"
              primary={this.state.show === 3}
              onClick={e => {
                e.preventDefault();
                if (this.state.show !== 3)
                  this.setState({ ...this.state, show: 3 });
              }}
            >
              Interesse
            </Button>
          </Form>
        </Container>
        {this.state.show === 0 && <List />}
        {this.state.show === 1 && <Registrations />}
        {this.state.show === 2 && <Biddings />}
        {this.state.show === 3 && <Info />}
      </div>
    );
  }
}

export default App;
