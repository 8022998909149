import React from "react";
import Cloud from "@ypi/client-sdk";
import { Grid } from "@nodeme/grid-react";
import Sensor from "react-visibility-sensor";

export default class Browser extends React.Component {
  _isMounted = false;
  _renderElement = () => "";

  constructor(props) {
    super(props);
    this.state = {
      elements: [],
      pending: false,
      error: false,
      fullyLoaded: false
    };

    this._renderElement = props.render;

    this.load = this.load.bind(this);
    this.saveSetState = this.saveSetState.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  saveSetState(newState) {
    if (this._isMounted) this.setState(newState);
  }

  async load() {
    if (!this.state.pending)
      this.saveSetState({ ...this.state, pending: true });
    else return;

    const { model, query, projection, sorting, limit } = this.props;
    const lastElement = this.state.elements.length
      ? this.state.elements[this.state.elements.length - 1].id
      : false;

    try {
      const result = await Cloud.models[model].get(
        query,
        projection,
        sorting,
        limit,
        lastElement
      );

      this.setState({
        ...this.state,
        pending: false,
        error: false,
        elements: [...this.state.elements, ...result.data],
        fullyLoaded: !result.data.length
      });
    } catch (err) {
      console.log(err);
      this.setState({
        ...this.state,
        pending: false,
        error: true
      });
    }
  }

  render() {
    const { error, pending, elements } = this.state;

    return (
      <div style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
        <Grid spacing={16} vertical>
          {elements.map((ele, i) =>
            this._renderElement(ele, i, elements, elements => {
              this.setState({ ...this.state, elements });
            })
          )}
          {pending && <div style={{ textAlign: "center" }}>Lade Werke...</div>}
          {error && (
            <div style={{ textAlign: "center" }}>
              Ein Fehler ist aufgetreten!
            </div>
          )}
          {!this.state.fullyLoaded && (
            <Sensor onChange={isVisible => (isVisible ? this.load() : null)}>
              <div style={{ height: "1px" }}></div>
            </Sensor>
          )}
        </Grid>
      </div>
    );
  }
}
