import React from "react";
import { Container } from "@nodeme/grid-react";
import Form from "@nodeme/forms-react/Form";
import Text from "@nodeme/forms-react/Text";
import Browser from "./Browser";

export default class Registrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        event: "c380836a-c639-4eec-a333-1d9fa7d1c3b9"
      },
      projection: ["id", "lot", "email", "createdAt"],
      sorting: { f: "createdAt", d: "desc" },
      limit: 12,
      registrations: []
    };
  }

  async componentDidMount() {}

  render() {
    const { query, projection, sorting, limit } = this.state;
    return (
      <Container notFluid>
        <Browser
          model="Info"
          query={query}
          projection={projection}
          sorting={sorting}
          limit={limit}
          render={(bid, index, elements, setElements) => {
            return (
              <div
                key={bid.id}
                style={{ borderBottom: "1px solid rgba(0, 0 , 0, 0.2)" }}
              >
                <Form>
                  <Text
                    xs={10}
                    md={5}
                    lg={10 / 3}
                    label="Email"
                    value={bid.email}
                    readOnly
                  />
                  <Text
                    xs={10}
                    md={5}
                    lg={10 / 3}
                    label="Lot"
                    value={bid.lot}
                    readOnly
                  />
                </Form>
              </div>
            );
          }}
        />
      </Container>
    );
  }
}
